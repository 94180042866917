import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import Home from './Pages/Home';
import Services from './Pages/Services';
import Contact from './Pages/Contact';
import Error from './Pages/Error';
import Team from './Pages/Team';

function App() {

  return (
    <Router>
      <div className="App">
        <Switch>
          <Route path="/services">
            <Services />
          </Route>
          <Route path="/contact">
            <Contact />
          </Route>
          <Route path="/team">
            <Team />
          </Route>
          <Route path="/pay" component={() => {
            window.location.replace("https://buy.stripe.com/9AQ7sWclO9jg4o0eUU");
            return null;
          }}/>
          <Route exact path="/">
            <Home />
          </Route>
          <Route path="*" component={() => {
            if (process.env.NODE_ENV === 'development') {
              window.location.replace("https://solaceintegrations.com/error");
            } else {
              window.location.replace("/error");
            }
            return null;
          }}/>
        </Switch>
      </div>
    </Router>
  );
}

function About() {
  return <h2>About</h2>;
}

function Users() {
  return <h2>Users</h2>;
}

export default App;
