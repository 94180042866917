import Header from '../Components/Header';
import Footer from '../Components/Footer';
import React, { useState, useEffect } from 'react';

function Home() {
    useEffect(() => {
        const $ = window.$;

        $('.owl-one').owlCarousel({
            loop: true,
            margin: 0,
            nav: false,
            responsiveClass: true,
            autoplay: true,
            autoplayTimeout: 9000,
            autoplaySpeed: 2000,
            autoplayHoverPause: false,
            responsive: {
                0: {
                    items: 1
                },
                480: {
                    items: 1
                },
                667: {
                    items: 1
                },
                1000: {
                    items: 1
                }
            }
        })
        $("#owl-demo1").owlCarousel({
            loop: true,
            margin: 20,
            nav: false,
            autoplay: true,
            responsiveClass: true,
            autoplaySpeed: 1000,
            autoplayTimeout: 5000,
            autoplayHoverPause: false,
            responsive: {
                0: {
                    items: 1,
                    nav: false
                },
                736: {
                    items: 1,
                    nav: false
                },
                1000: {
                    items: 1,
                    nav: false,
                    loop: false
                }
            }
        })
        $(window).on("scroll", function () {
            var scroll = $(window).scrollTop();

            if (scroll >= 80) {
                $("#site-header").addClass("nav-fixed");
                $("#main-logo").attr("src", "assets/images/logo-black.png");
            } else {
                $("#site-header").removeClass("nav-fixed");
                $("#main-logo").attr("src", "assets/images/logo.png");
            }
        });

        //Main navigation Active Class Add Remove
        $(".navbar-toggler").on("click", function () {
            $("header").toggleClass("active");
        });
        $(document).on("ready", function () {
            if ($(window).width() > 991) {
                $("header").removeClass("active");
            }
            $(window).on("resize", function () {
                if ($(window).width() > 991) {
                    $("header").removeClass("active");
                }
            });
        });

        $('.owl-carousel').each(function() {
            //Find each set of dots in this carousel
          $(this).find('.owl-dot').each(function(index) {
            //Add one to index so it starts from 1
            $(this).attr('aria-label', index + 1);
          });
        });

        window.onscroll = function () {
            scrollFunction()
        };

        function scrollFunction() {
            if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
                document.getElementById("movetop").style.display = "block";
            } else {
                document.getElementById("movetop").style.display = "none";
            }
        }

    });

    return (
        <div style={{textAlign: "left"}}>
            <Header />

            <section className="w3l-main-slider banner-slider" style={{marginTop:"44px"}} id="home">
                <div className="owl-one owl-carousel owl-theme">
                <div className="item">
                        <div className="slider-info banner-view banner-top3">
                            <div className="container">
                                <div className="banner-info header-hero-19">
                                    <p className="text-tag">Contact Us</p>
                                    <h1 className="title-hero-19" style={{ marginBottom: "0px" }}>Have a New Technology Project or Need Small Business IT Support?</h1>
                                    <div className="w3banner-buttons">
                                        <a href="/contact" className="btn btn-style btn-primary mt-sm-5 mt-4">Contact Us</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="item">
                        <div className="slider-info banner-view banner-top2">
                            <div className="container">
                                <div className="banner-info header-hero-19">
                                    <p className="text-tag">Our Committment</p>
                                    <h1 className="title-hero-19 mb-4">Great Technology, Even Better Service</h1>
                                    <p className="hnypara">By utilizing industry-trusted IT products and the latest tools, we ensure that our support for your small business is both efficient and up-to-date. Staying ahead of modern IT trends allows us to deliver seamless solutions with minimal disruption.</p>
                                    <div className="w3banner-buttons d-flex">
                                        <a href="#brands" className="btn btn-style btn-primary mt-sm-5 mt-4">View Product Lineup</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="item">
                        <div className="slider-info banner-view banner-top1">
                            <div className="container">
                                <div className="banner-info header-hero-19">
                                    <p className="text-tag">About Us</p>
                                    <h1 className="title-hero-19">Small Business and Home Office IT Services</h1>
                                    <div className="d-grid grid-col-3 mai-hero-19">
                                        <div className="grid-hero-19">
                                            <span className="fas fa-network-wired"></span>
                                            <div className="right-cont-19">
                                                <p className="sub-paragraph"> </p>
                                                <h5><a href="services">Networking</a></h5>
                                            </div>
                                        </div>
                                        <div className="grid-hero-19">
                                            <span className="fas fa-tv"></span>
                                            <div className="right-cont-19">
                                                <p className="sub-paragraph"></p>
                                                <h5><a href="services">Digital Signage</a></h5>
                                            </div>
                                        </div>
                                        <div className="grid-hero-19 third-gdw3">
                                            <span className="fas fa-cloud"></span>
                                            <div className="right-cont-19">
                                                <p className="sub-paragraph"> </p>
                                                <h5><a href="services">Cloud Services</a></h5>
                                            </div>
                                        </div>
                                        <div className="grid-hero-19">
                                            <span className="fas fa-laptop-house"></span>
                                            <div className="right-cont-19">
                                                <p className="sub-paragraph"> </p>
                                                <h5><a href="services">IT Help Desk & Remote Support</a></h5>
                                            </div>
                                        </div>
                                        <div className="grid-hero-19">
                                            <span className="fas fa-database"></span>
                                            <div className="right-cont-19">
                                                <p className="sub-paragraph"> </p>
                                                <h5><a href="services">Data Backups & Recovery</a></h5>
                                            </div>
                                        </div>
                                        <div className="grid-hero-19 third-gdw3">
                                            <span className="fas fa-shield-alt"></span>
                                            <div className="right-cont-19">
                                                <p className="sub-paragraph"> </p>
                                                <h5><a href="services">Cybersecurity & Firewall Solutions</a></h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    
                
                </div>
            </section>

            <div className="w3l-3-grids py-5" id="grids-3">
                <div className="container py-md-5 py-2">

                    <div className="row">
                        <div className="col-lg-8 pr-lg-3 mb-3">
                            <div className="title-content text-left">
                                <h6 className="title-subhny mb-2">About Us</h6>
                                <h1 className="title-w3l">Your Local Technology Company</h1>
                            </div>
                        </div>

                    </div>
                    <div className="mt-lg-0 mt-md-5 mt-4 mb-3 text-left">

                        <p className="pr-lg-5">Solace Integrations is a comprehensive technology services provider and integrator located in Westchester County, NY. As businesses increasingly rely on complex technology, managing and maintaining these systems can be overwhelming. Our skilled technicians simplify this process with expert support in IT, Networking, Point of Sale, Cloud Services, and more. Explore our full range of services below to discover how we can assist you.</p>
                        <div className="w3banner-buttons">
                            <a href="/services" className="btn btn-style btn-primary mt-sm-3 mb-4">View All Services</a>
                        </div>
                    </div>

                    <div id="brands" className="row">
                        <div className="col-lg-6 pr-lg-3 mb-3">
                            <div className="title-content text-left">
                                <h6 className="title-subhny mb-2">Brands We Use</h6>
                                <h1 className="title-w3l">Industry Respected Brands</h1>
                            </div>
                        </div>

                    </div>

                    <div className="row pt-md-0 pt-5">
                        <div className="col-lg-4 col-6 mt-2">
                            <a href="https://synology.com" target="_blank" className="d-block zoom">

                                <div className="grids3-info">
                                    <img src="assets/images/synology-logo.webp" alt="Synology Logo" className="img-fluid news-image" />
                                   
                                </div>
                            </a>

                        </div>
                        <div className="col-lg-4 col-6 mt-2">
                            <a href="https://www.ui.com" target="_blank" className="d-block zoom">

                                <div className="grids3-info">
                                    <img src="assets/images/ubiquiti-logo_processed.png" alt="Ubiquiti Logo" className="img-fluid news-image" />
                                   
                                </div>
                            </a>

                        </div>
                        <div className="col-lg-4 col-6 mt-2">
                            <a href="https://www.microsoft.com" target="_blank" className="d-block zoom">

                                <div className="grids3-info">
                                    <img src="assets/images/microsoft-logo.webp" alt="Microsoft Logo" className="img-fluid news-image" />
                                   
                                </div>
                            </a>

                        </div>
                        <div className="col-lg-4 col-6 mt-2">
                            <a href="https://www.atera.com" target="_blank" className="d-block zoom">

                                <div className="grids3-info">
                                    <img width="555px" height="383px" src="assets/images/atera.png" alt="Atera Logo" className="img-fluid news-image" />
                                   
                                </div>
                            </a>

                        </div>
                        <div className="col-lg-4 col-6 mt-2">
                            <a href="https://stripe.com/" target="_blank" className="d-block zoom">

                                <div className="grids3-info">
                                    <img src="assets/images/stripe-logo.webp" alt="Stripe Logo" className="img-fluid news-image" />
                                   
                                </div>
                            </a>

                        </div>
                        <div className="col-lg-4 col-6 mt-2">
                            <a href="https://www.n-able.com/" target="_blank" className="d-block zoom">

                                <div className="grids3-info">
                                    <img src="assets/images/nable-logo.jpg" alt="N-Able Logo" className="img-fluid news-image" />
                                   
                                </div>
                            </a>

                        </div>
                        <div className="col-lg-4 col-6 mt-2">
                            <a href="https://bitwarden.com/" target="_blank" className="d-block zoom">

                                <div className="grids3-info">
                                    <img src="assets/images/bitwarden-logo.png" alt="Bitwarden Logo" className="img-fluid news-image" />
                                   
                                </div>
                            </a>

                        </div>
                        <div className="col-lg-4 col-6 mt-2">
                            <a href="https://samsung.com" target="_blank" className="d-block zoom">

                                <div className="grids3-info">
                                    <img src="assets/images/samsung-logo.webp" alt="Samsung Logo" className="img-fluid news-image" />
                                   
                                </div>
                            </a>

                        </div>
                    </div>
                </div>
            </div>

            <section class="w3l-featureshny py-5" id="features">
                <div class="container py-md-5">
                    <div class="row">
                        <div class="col-lg-5 pr-lg-5 mb-lg-0 mb-5">
                            <div class="w3l-header-sec text-left mb-md-5 mb-4">
                                <h6 class="title-subhny mb-2" style={{color:"white"}}>About Us</h6>
                                <h1 style={{color:"white"}} class="">View Our Team of Technology Experts</h1>
                                <a href="/team" class="btn btn-primary btn-white btn-style mt-lg-5 mt-4">Our Team</a>
                            </div>
                        </div>
                    
                    </div>
                </div>
            </section>

            <Footer/>
        </div>
    );
}

export default Home;