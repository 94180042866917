import Header from '../Components/Header';
import Footer from '../Components/Footer';

import {Helmet} from "react-helmet";

export default function () {
  let list = ["Cloud storage and onsite backups", "Disaster recovery", "voip phone installation and servicing","small business and home office IT solutions", "digital signage and kiosks", 
    "IT helpdesk support","conferencing software solutions", "productivity software solutions (Microsoft 365 and Google Workspace)", "Email Hosting and Management", "Cloud services management (AWS, Google Cloud, AZure, heroku)",
    "Point of sale installation and servicing", "Point of sale customization and configuration", 
    "Hardware procurement and asset management (Laptops, peripherals, business phones, cell phones, networking equipment, printers)",
    "Mobile device management (iPad and iOS devices, Apple business manager, Apple for education, Intune)", "network monitoring aND management","patch management",
    "Microsoft Entra (Azure Active directory)", 
    "IT consulting - strategy and planning","Security and compliance", "Cybersecurity attack simulations and training","endpoint security management (firewalls, antivirus)",
    "Remote monitoring and management (rmm)", "Software License Management"];
  let listHalfLen = Math.ceil(list.length / 2);
  let list2 = list.splice(0, listHalfLen);

  return (
    <div>
      <Helmet>
        <title>Our Services | Solace Integrations | Hawthorne, NY</title>
        <link rel="canonical" href="https://solaceintegrations.com/services" />
      </Helmet>
      <Header isFixed />
      <section className="services">
        <div className="py-5" style={{ backgroundColor: "rgb(62 74 97 / 71%)", marginTop: "7rem" }}>
          <div className="container py-md-5 py-2 text-center">
            <h3 class="services-title text-center mb-lg-5 mb-3 px-lg-5"><span class="sub-title">What we do.</span>Our Services</h3>
            <p class="main_p4 mt-4 mb-4 pt-2 text-center mx-auto text-white">
              Solace Integrations offers professional IT support services for small business and home offices
              - from remote support, cloud storage, cybersecurity solutions, networking, digital signage, data recovery, and mobile device management. Setting up systems
              is only part of the picture. We also provide support to help maintain those systems and make sure your business stays
              running as it should every day.</p>
            <div id="all-services" class="mt-4">
              <h4 class="mb-2 text-white">List of All Services</h4>
              <div class="row">
                <div class="col-md-6 text-center mt-4">
                  {list2.map(function (text, index) {
                    return <p class="service-label sub-title-team">
                      <img class="check" alt="Check mark" src="assets/images/check.svg" />
                      {text}</p>;
                  })}
                </div>
                <div class="col-md-6 text-center mt-4">
                  {list.map(function (text, index) {
                    return <p class="service-label sub-title-team">
                      <img class="check" alt="Check mark" src="assets/images/check.svg" />
                      {text}</p>;
                  })}
                </div>
              </div>
            </div>
            <a href="/contact" class="btn btn-primary btn-style mt-lg-5 mt-4">Contact Us Today</a>
          </div>
          
        </div>
      </section>
      <Footer/>

    </div>
  );
}